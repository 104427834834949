@import "../../../assets/stylesheets/config/index";

.o-section {
    background-color: #ffffff;

    &__header {}

    &__body {}
}

.with-gradient {
    position: relative;
    margin-top: 8px;

    &:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: -8px;
        left: 0;
        width: 100%;
        height: 15px;
        border-radius: 5px;
        box-shadow: inset 0 -1px 1px 0 rgba(0, 0, 0, 0.06);
    }

    &--1:before {
        background-image: linear-gradient(to left, #f2ff71, #71fff0 49%, #7ddeff);
    }

    &--2:before {
        background-image: linear-gradient(to left, #71eaff, #b5d5ff 49%, #f0d8ff);
    }
}
