@import '../../../assets/stylesheets/config/index';

.a-lazy-image {
  position: relative;
  width: 100%;
  height: 100%;
  // background-color: map-get($colors, gray-color);

  img {
    width: 100%;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }

  &--preload {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &--loaded {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    opacity: 0;
    transition: opacity 264ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &--fade-in {
    opacity: 1 !important;
  }

  &.\--bg {
    opacity: 0;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: opacity 264ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
}
