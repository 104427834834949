@import "../../../assets/stylesheets/config/index";

.m-rating {
    display: flex;
    align-items: center;
    font-size: map-get($typography-size, overline);
    line-height: 1.23;

    .a-stars {
        margin-right: map-get($size, 1);
    }

    span:not(:last-child) {
        margin-right: map-get($size, 2);
    }
}
