@import "../../../assets/stylesheets/config/index";

.o-modal {
    &__overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        z-index: 10000; // 1060;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
        padding: 30px;
        background-color: rgba(144, 144, 144, 0.98); // rgba(29, 32, 37, 0.7);

        @include respond-above(lg) {
            padding: 30px 0;
        }
    }

    &__header {
        padding: 44px 30px 28px;

        @include respond-above(lg) {
            padding: 42px 98px 16px;
        }
    }

    &__body {
        padding: 0 30px 40px;

        @include respond-above(lg) {
            padding: 24px 98px 40px;
        }

        p + p {
            margin-top: 16px;
        }
    }

    &__content {
        position: relative;
        width: 100%;
        max-width: 750px;
        margin: auto;
        background-color: #fff;
        border-radius: 5px;
        outline: none;

        &:before {
            content: "";
            position: absolute;
            z-index: -1;
            top: -8px;
            left: 0;
            width: 100%;
            height: 15px;
            background-image: map-get($gradients, 2);
            border-radius: 5px;
            box-shadow: inset 0 -1px 1px 0 rgba(0, 0 ,0, .06);
        }
    }

    &__close {
        position: absolute;
        top: 16px;
        right: 28px;
        width: 22px;
        height: 22px;
        cursor: pointer;

        &:after,
        &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            display: block;
            width: 22px;
            height: 2px;
            background-color: #717479;
            border-radius: 6px;
        }

        &:after {
            transform: rotate(-45deg);
        }

        &:before {
            transform: rotate(45deg);
        }
    }
}

.o-video-modal {
    padding-bottom: 8px;
}

body.ReactModal__Body--open,
body.is-plaid-closed.ReactModal__Body--open {
    overflow: hidden !important;
}