@import "../../../assets/stylesheets/config/index";

.a-meta {
    display: inline-flex;
    align-items: center;

    &__item {
        display: flex;
        align-items: center;
    }

    &__item + &__item {
        &:before {
            content: "";
            display: block;
            width: 1px;
            height: 1.5625rem;
            margin: 0 1rem;
            background-color: map-get($typography-accent, gray-4);
            opacity: 0.2;
        }
    }
}
