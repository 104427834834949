@import "../../../assets/stylesheets/config/index";

.a-h1 {
    font-size: 2.125rem;

    @include respond-between(sm, lg) {
        line-height: 1.41;
    }

    @include respond-above(lg) {
        font-size: map-get($typography-size , h1);
    }
}

.a-h2 {
    font-size: 2.125rem;

    @include respond-between(sm, lg) {
        font-size: 34px;
        line-height: 1.18;
    }

    @include respond-above(lg) {
        font-size: map-get($typography-size, h2);
    }
}

.a-h3 {
    font-size: 1.3125rem;

    @include respond-above(lg) {
        font-size: map-get($typography-size, h3);
    }
}

.a-h4 {
    font-size: map-get($typography-size, h4);
}

.a-h5 {
    font-size: map-get($typography-size, h5);
}

.a-h6 {
    font-size: map-get($typography-size, h6);
}

.a-subtitle1 {
    font-size: map-get($typography-size, subtitle-1);
    line-height: 1.6;
}

.a-subtitle2 {
    font-size: map-get($typography-size, subtitle-2);
}

.a-subtitle3 {
    font-size: map-get($typography-size, subtitle-3);
}

.a-badge {
    display: flex;
    align-items: center;
    font-size: map-get($typography-size, caption);
    line-height: 1.23;
    letter-spacing: map-get($typography-spacing, 1);
    text-transform: uppercase;
    color: map-get($typography-accent, gray-3);

    img {
        margin-right: 0.625rem;
    }
}

.a-body1 {
    font-size: map-get($typography-size, body-1);
}

.a-body2 {
    font-size: map-get($typography-size, body-2);
}

.a-caption {
    font-size: map-get($typography-size, caption);
    line-height: 1.23;
}

.a-overline {
    @include overline-font();
}

.\--500 {
    font-weight: 500;
}

.\--600 {
    font-weight: 600;
}

.\--700 {
    font-weight: 700;
}

.\--uppercase {
    text-transform: uppercase;
}

.\--color-primary {
    color: map-get($typography-accent, primary);
}

.\--color-gray-1 {
    color: map-get($typography-accent, gray-1);
}

.\--color-gray-2 {
    color: map-get($typography-accent, gray-2);
}

.\--color-gray-3 {
    color: map-get($typography-accent, gray-3);
}

.\--color-gray-4 {
    color: map-get($typography-accent, gray-4);
}

.\--color-gray-5 {
    color: map-get($typography-accent, gray-5);
}

.\--color-gray-6 {
    color: map-get($typography-accent, gray-6);
}

.\--text-left {
    text-align: left;
}

.\--text-center {
    text-align: center;
}

.\--text-right {
    text-align: right;
}