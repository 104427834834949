@import "../../../assets/stylesheets/config/index";

.o-landing-section {
    position: relative;
    padding: map-get($size, 5) map-get($size, 4);
    text-align: center;

    @include respond-between(sm, md) {
        display: flex;
        align-items: center;
        min-height: calc(100vh - 5rem);
        padding: map-get($size, 8) map-get($size, 4);
    }

    @include respond-above(md) {
        display: flex;
        align-items: flex-start;
        text-align: left;
    }

    @include respond-between(md, lg) {
        padding-top: 10rem;
        padding-bottom: 10rem;
    }

    @include respond-above(lg) {
        padding: 180px 0 318px;
    }

    p {
        font-size: 16px;
        line-height: 1.83;
        color: #294048;

        @include respond-between(md, lg) {
            margin-bottom: map-get($size, 5);
        }

        @include respond-above(lg) {
            margin-bottom: 70px;
            font-size: 18px;
            line-height: 1.85;
        }
    }

    h2 {

        @include respond-below(md) {
            margin-left: 6px;
        }

        @include respond-above(md) {
            display: block;
        }

        @include respond-between(md, lg) {
            margin-top: map-get($size, 5);
        }

        @include respond-above(lg) {
            margin: map-get($size, 5) + 0.125rem 0 0;
        }
    }

    .a-h1 {
        display: inline;
        font-size: 42px;
        line-height: 1.14;
        color: #0c2c37;

        @include respond-above(md) {
            display: block;
        }

        @include respond-between(md, lg) {
            font-size: 3.25rem;
        }

        @include respond-above(lg) {
            font-size: 4rem;
            line-height: 1.08;
        }
    }

    .a-button {
        width: 100%;
        max-width: 257px;
        height: 42px;
        margin: 9px auto 20px;
        box-shadow: 0 14px 30px 0 rgba(44, 79, 91, 0.15), 0 2px 3px 0 rgba(44, 68, 77, 0.18);
        letter-spacing: 0.6px;

        @include respond-between(sm, md) {
            margin-bottom: map-get($size, 4);
        }

        @include respond-above(md) {
            width: 257px;
            height: 42px;
            margin-bottom: 0;
            margin-left: 0;
            font-size: 15px;
            font-weight: 600;
            letter-spacing: -0.01px;
        }
    }

    .t-container {
        display: flex;
        flex-direction: column;

        @include respond-between(sm, md) {
            max-width: 27.625rem;
            padding: 0;
        }

        @include respond-above(md) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__content.is-1 {
        margin-bottom: map-get($size, 3);

        @include respond-between(sm, md) {
            margin-bottom: map-get($size, 5);
        }

        @include respond-above(md) {
            margin: 0;
        }

        @include respond-between(md, lg) {
            width: 20rem;
        }

        @include respond-above(lg) {
            flex-shrink: 0;
            width: 365px;
            margin: 0;
        }
    }

    &__content.is-2 {
        display: flex;
        flex-direction: column;

        @include respond-below(sm) {
            order: 1;
            margin: map-get($size, 1) 0 map-get($size, 4);
        }

        @include respond-between(md, lg) {
            width: 20rem;
        }

        @include respond-above(lg) {
            width: 365px;
            margin: 26px map-get($size, 1) 0 0;
        }

        p {
            order: 2;

            @include respond-below(sm) {
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                margin-top: - map-get($size, 6);
                padding: 0 20px;
            }

            @include respond-above(md) {
                order: 0;
            }
        }

        span {
            font-size: 14px;
            font-weight: 600;
            line-height: 2.29;
            letter-spacing: 1.4px;
            text-transform: uppercase;
            color: #787a7d;
        }
    }

    &__object {
        width: 18.375rem;
        height: 19.25rem;
        margin: 0 auto 2px;

        @include respond-below(sm) {
            order: 2;
        }

        @include respond-between(sm, md) {
            width: 23.75rem;
            height: 24.8125rem;
            margin-bottom: map-get($size, 4);
        }

        @include respond-above(md) {
            position: absolute;
            z-index: -1;
            top: 68px;
            left: 47.6%;
            transform: translate(-50%, 0);
        }

        @include respond-between(md, lg) {
            width: 33.5rem;
            height: 35rem;
        }

        @include respond-above(lg) {
            width: 46.75rem;
        }
    }
}

.o-feature-section {
    display: flex;
    flex-flow: row wrap;
    padding: 10.3125rem 1.125rem 1.5625rem;

    @include respond-above(lg) {
        padding: map-get($size, 6) map-get($size, 3);
    }
}

.m-feature {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 0 0 100%;
    max-width: 100%;
    background-color: #f4f9fa;
    border-radius: map-get($border-radius, md);
    text-align: center;

    @include respond-above(md) {
        align-items: center;
        justify-content: flex-start;
        border: 2px solid #fff;
    }

    @include respond-between(md, lg) {
        padding: 0 2rem;
    }

    @include respond-above(lg) {
        padding: 0 6.7rem;
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        background-color: #59b2d1;
        box-shadow: 0 25px 30px 0 rgba(44, 79, 91, 0.1);
        border-radius: 50%;
        width: 65px;
        height: 65px;
        margin: -26px auto 24px;
        font-size: 24px;
        font-weight: 600;
        line-height: 65px;
        color: #fff;

        @include respond-above(lg) {
            margin-top: 0;
        }
    }

    &__image {
        height: 0;
        padding-bottom: 48.4%;
    }

    h2 {
        margin-bottom: 0.75rem;
        font-size: 1.5rem;
        line-height: 1.67;
        color: #0c2c37;

        @include respond-above(lg) {
            margin-bottom: map-get($size, 4);
            font-size: 2.125rem;
        }

        @include respond-above(lg) {
            margin-bottom: 26px;
        }
    }

    p {
        padding: 0 map-get($size, 3) map-get($size, 5);
        font-size: 0.875rem;
        line-height: 2.23;
        color: #294048;

        @include respond-above(md) {
            padding: 0;
            font-size: 1rem;
            line-height: 1.94;
        }
    }

    &.\--half {
        @include respond-above(md) {
            justify-content: center;
            flex: 0 0 50%;
            max-width: 50%;
        }

        @include respond-between(md, lg) {
            height: 28rem;
        }

        @include respond-above(lg) {
            height: 35rem;
        }

        .m-feature__content {
            @include respond-between(sm, md) {
                width: 32.5rem;
            }

            @include respond-between(md, lg) {
                width: 26.875rem;
            }

            @include respond-above(lg) {
                width: 29.375rem;
            }   
        }
    }

    &.is-3 {
        @include respond-above(lg) {
            .m-feature__icon {
                margin-top: -36px;
            }
        }

        .m-feature__content {
            @include respond-between(sm, md) {
                width: 32.5rem;
            }

            @include respond-between(md, lg) {
                width: 34rem;
            }

            @include respond-above(lg) {
                width: 33.625rem;
            }
        }
    }

    & + & {
        margin-top: 12px;

        @include respond-above(md) {
            margin-top: 0;
        }
    }
}

.o-video-section {
    position: relative;

    @include respond-above(md) {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35.625rem;
    }

    &__bg-image {
        display: flex;
        align-items: center;
        justify-content: center;
        background-position: bottom right !important;

        @include respond-below(md) {
            height: 15.375rem;
            margin-bottom: 18px;
        }

        @include respond-above(md) {
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            margin: auto;
        }
    }

    &__content {
        padding: 0 30px;
        text-align: center;

        @include respond-above(md) {
            max-width: 392px;
            margin: auto;
            padding: 0;
        }
    }

    h2 {
        margin-bottom: map-get($size, 1);
        font-size: 24px;
        line-height: 1.67;

        @include respond-above(md) {
            margin-top: map-get($size, 4);
            font-size: 34px;
            line-height: 1.45;
            color: #fff;
        }
    }

    p {
        font-size: 0.875rem;
        line-height: 2.23;
        color: #515e6d;

        @include respond-above(md) {
            font-size: 16px;
            line-height: 1.94;
            color: #fff;
        }
    }

    .play-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 7.5rem;
        height: 7.5rem;
        margin: auto;
        -webkit-backdrop-filter: blur(100px);
        backdrop-filter: blur(100px);
        box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.02);
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        cursor: pointer;

        &:after {
            content: "";
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 13px 0 13px 20px;
            border-color: transparent transparent transparent #fff;
        }

        @include respond-below(md) {
            position: absolute;
            top: 64px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

.o-core-section {
    padding: 70px 30px 126px;
    color: #294048;

    @include respond-above(md) {
        padding-top: 86px;
    }

    &__content {
        margin-bottom: 32px;
        text-align: center;

        @include respond-above(md) {
            display: flex;
            margin-bottom: 70px;
            text-align: left;
        }

        p {
            font-size: 16px;
            line-height: 1.94;

            @include respond-above(md) {
                flex-shrink: 0;
                width: 304px;
                margin-left: auto;
            }
        }

        p + p {
            margin: 8px 0 0;

            @include respond-above(md) {
                margin: 0 0 0 40px;
            }
        }
    }

    &__object {
        @include respond-above(md) {
            display: flex;
        }

        @include respond-between(md, lg) {
            flex-flow: row wrap;
            max-width: 80%;
            margin: auto;
        }

        @include respond-above(lg) {
            align-items: flex-end;
            justify-content: space-between;
        }
    }

    h2 {
        margin-bottom: 14px;
        font-size: 24px;
        font-weight: 600;
        line-height: 1.67;
        color: #0c2c37;

        @include respond-above(md) {
            margin: -4px 0 0;
            font-size: 34px;
            line-height: 1.45;
        }
    }
}

.m-core {
    display: flex;
    flex-direction: column;
    max-width: 196px;
    margin: 0 auto;
    text-align: center;

    @include respond-above(md) {
        margin: 0;
    }

    @include respond-between(md, lg) {
        width: calc(100% / 3);
        max-width: inherit;
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin: auto;
        font-size: 34px;
        font-weight: 600;
        line-height: 1.45;
        color: #0c2c37;
    }

    &__image {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
    }

    &__name {
        font-size: 20px;
        line-height: 1.55;

        @include respond-above(md) {
            height: 62px;
        }
    }

    &.is-1 {
        .m-core__icon {
            width: 162px;
            height: 164px;
            margin-bottom: -10px;
        }
    }

    &.is-2 {
        @include respond-below(md) {
            margin-top: 90px;
        }

        .m-core__icon {
            width: 134px;
            height: 144px;
            margin-bottom: 18px;
        }
    }

    &.is-3 {
        @include respond-below(md) {
            margin-top: 80px;
        }

        .m-core__icon {
            width: 156px;
            height: 164px;
            margin-bottom: 2px;
        }
    }

    &.is-4 {
        @include respond-below(md) {
            margin-top: 70px;
        }

        .m-core__icon {
            width: 154px;
            height: 170px;
            margin-bottom: 6px;
        }
    }

    &.is-5 {
        @include respond-below(md) {
            margin-top: 80px;
        }

        .m-core__icon {
            width: 165px;
            height: 155px;
            margin-bottom: 8px;
            color: #fff;
        }
    }
}

.o-cards-section {
    position: relative;
    padding: 3.675rem 30px 380px;
    background-color: #41d0e6;
    overflow: hidden;
    color: #fff;

    @include respond-above(md) {
        height: 500px;
        padding: 6.25rem 0;
    }

    h2 {
        margin-bottom: 1.125rem;
        font-size: 1.5rem;
        line-height: 1.67;
        color: #fff;

        @include respond-above(md) {
            margin-bottom: 14px;
            font-size: 2.125rem;
            line-height: 1.45;
        }
    }

    p {
        margin-bottom: map-get($size, 5);
        font-size: 0.875rem;
        line-height: 2.24;

        @include respond-above(md) {
            margin-bottom: 30px;
            font-size: 1rem;
            line-height: 1.94;
        }
    }

    .a-button {
        width: 16.625rem;
        height: 43px;
        font-size: 14px;
    }

    &__content {
        position: relative;
        z-index: 10;
        text-align: center;

        @include respond-above(md) {
            width: 29.1875rem;
            text-align: left;
        }
    }

    &__object {
        position: absolute;
        top: 8%;
        left: 93%;
        transform: translate(-50%, 0);
        width: 1300px;

        @include respond-above(md) {
            top: -164px;
            left: 50%;
            transform: translate(-33.6%, 0);
        }
    }
}
