@import '../../../assets/stylesheets/config/index';

.t-container {
  max-width: $desktop-container;
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  @include respond-between(sm, md) {
    max-width: 45rem;
  }

  @include respond-between(sm, lg) {
    padding-right: map-get($size, 1);
    padding-left: map-get($size, 1);
  }

  @include respond-between(md, lg) {
    max-width: 60rem;
  }

  @include respond-above(lg) {
    padding-right: $gutter-width;
    padding-left: $gutter-width;
  }

  @include respond-between(md, lg) {
    max-width: 960px;
  }

  &.\--lg {
    @include respond-above(sm) {
      max-width: 90rem;
      padding: 0 2.8125rem;
    }
  }
}

.t-content {
  flex-grow: 1;

  &--lg-padd {
    padding: 3.75rem;
  }

  &__search {
    max-width: 26.375rem;
  }
}

.t-main {
  position: relative;
  min-height: 100vh;
  margin-top: $header-height--xs;

  @include respond-between(sm, md) {
    min-height: calc(100vh - (80px + #{$footer-height--lg}));
    margin-top: 65px;
  }

  @include respond-above(md) {
    min-height: calc(100vh - (#{$header-height--lg}));
    margin-top: $header-height--lg;
  }

  .\--remove-min-height & {
    min-height: initial;
  }

  .\--default-min-height & {
  }
}

.t-row {
  display: flex;
  flex-flow: row wrap;

  @include respond-between(sm, lg) {
    margin-right: -map-get($size, 1);
    margin-left: -map-get($size, 1);
  }

  @include respond-above(lg) {
    margin-right: -map-get($size, 2) + 0.125rem;
    margin-left: -map-get($size, 2) + 0.125rem;
  }

  &.\--sm {
    margin-right: -map-get($size, 1);
    margin-left: -map-get($size, 1);

    .t-col {
      padding-right: map-get($size, 1);
      padding-left: map-get($size, 1);
    }
  }

  &.\--flex {
    .t-col {
      flex: 1 1;
    }
  }
}

.t-col {
  flex: 0 0 100%;
  max-width: 100%;

  @include respond-between(sm, lg) {
    padding-right: map-get($size, 1);
    padding-left: map-get($size, 1);
  }

  @include respond-above(lg) {
    padding-right: map-get($size, 2) - 0.125rem;
    padding-left: map-get($size, 2) - 0.125rem;
  }
}

$grid-columns: 12;
@mixin make-columns($breakpoint, $upper) {
  @if ($breakpoint == 'xs') {
    @include respond-below($breakpoint) {
      @for $i from 1 through $grid-columns {
        .\--#{$breakpoint}-#{$i} {
          flex: 0 0 (100% / $grid-columns * $i);
          max-width: (100% / $grid-columns * $i);
        }
      }
    }
  } @else if ($breakpoint == 'lg') {
    @include respond-above($breakpoint) {
      @for $i from 1 through $grid-columns {
        .\--#{$breakpoint}-#{$i} {
          flex: 0 0 (100% / $grid-columns * $i);
          max-width: (100% / $grid-columns * $i);
        }
      }
    }
  } @else {
    @include respond-between($breakpoint, $upper) {
      @for $i from 1 through $grid-columns {
        .\--#{$breakpoint}-#{$i} {
          flex: 0 0 (100% / $grid-columns * $i);
          max-width: (100% / $grid-columns * $i);
        }
      }
    }
  }
}

@include make-columns(xs, '');
@include make-columns(sm, md);
@include make-columns(md, lg);
@include make-columns(lg, '');

// Margins
.\--mt-1 {
  margin-top: map-get($size, 1);
}
.\--mt-2 {
  margin-top: map-get($size, 2);
}
.\--mt-3 {
  margin-top: map-get($size, 3);
}
.\--mt-4 {
  margin-top: map-get($size, 4);
}
.\--mt-5 {
  margin-top: map-get($size, 5);
}
.\--mt-6 {
  margin-top: map-get($size, 6);
}
.\--mt-7 {
  margin-top: map-get($size, 7);
}
.\--mt-8 {
  margin-top: map-get($size, 8);
}
.\--mt-9 {
  margin-top: map-get($size, 9);
}
.\--mt-10 {
  margin-top: map-get($size, 10);
}
.\--mt-11 {
  margin-top: map-get($size, 11);
}
.\--mt-12 {
  margin-top: map-get($size, 12);
}

.\--mb-1 {
  margin-bottom: map-get($size, 1);
}
.\--mb-2 {
  margin-bottom: map-get($size, 2);
}
.\--mb-3 {
  margin-bottom: map-get($size, 3);
}
.\--mb-4 {
  margin-bottom: map-get($size, 4);
}
.\--mb-5 {
  margin-bottom: map-get($size, 5);
}
.\--mb-6 {
  margin-bottom: map-get($size, 6);
}
.\--mb-7 {
  margin-bottom: map-get($size, 7);
}
.\--mb-8 {
  margin-bottom: map-get($size, 8);
}
.\--mb-9 {
  margin-bottom: map-get($size, 9);
}
.\--mb-10 {
  margin-bottom: map-get($size, 10);
}
.\--mb-11 {
  margin-bottom: map-get($size, 11);
}
.\--mb-12 {
  margin-bottom: map-get($size, 12);
}
