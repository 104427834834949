//-- Color palette
$colors: (
  hawkes-blue-color: #dde1e9,
  solitude-color: #f3f5f6,
  gold-color: #f39e1d,
  navy-700-color: #386488,
  navy-800-color: #113757,
  navy-900-color: #0a243a,
  blue-300-color: #c3e7fa,
  blue-400-color: #13afd3,
  blue-500-color: #00698d,
  gray-color: #f8f9fb,
  gray-100-color: #f2f4f7,
  gray-200-color: #eaedf2,
  gray-300-color: #dcdfe4,
  gray-400-color: #b9bcc1,
  gray-500-color: #9a9da2,
  gray-600-color: #717479,
  gray-700-color: #5d6065,
  gray-800-color: #3e4146,
  gray-900-color: #1d2025,
  warm-gray: #979797
);

$body-color: map-get($colors, gray-800-color);
$headings-color: map-get($colors, gray-900-color);

//-- Unit size
$size-unit: 0.5;
$size: (
  1: $size-unit * 1rem,
  2: $size-unit * 2rem,
  3: $size-unit * 3rem,
  4: $size-unit * 4rem,
  5: $size-unit * 5rem,
  6: $size-unit * 6rem,
  7: $size-unit * 7rem,
  8: $size-unit * 8rem,
  9: $size-unit * 9rem,
  10: $size-unit * 10rem,
  11: $size-unit * 11rem,
  12: $size-unit * 12rem
);

//-- Radius
$border-radius: (
  xs: 0.1875rem,
  md: 0.25rem,
  lg: 0.3125rem
);

//-- Typography font
$typography-font: (
  body: 'Source Serif Pro',
  heading: 'IBM Plex Sans',
  heading-condensed: 'IBM Plex Sans Condensed',
  interface: 'IBM Plex Sans',
  overline: 'IBM Plex Mono'
);

//-- Typography size
$typography-size: (
  h1: 3.75rem,
  // 60px
    h2: 2.5rem,
  // 40px // h1
    h3: 1.875rem,
  // 30px // h2
    h4: 16px,
  h5: '',
  h6: '',
  subtitle-1: 1.25rem,
  // 20px
    subtitle-2: 1.1875rem,
  // 19px
    subtitle-3: 0.875rem,
  // 14px
    body-1: 1.25rem,
  // 20px
    body-2: 0.9375rem,
  // 15px
    button: 0.9375rem,
  // 15px
    caption: 0.8125rem,
  // 13px
    overline: 0.8125rem,
  // 13px // text-uppercase
    small: 0.75rem,
  // 12px
);

// 28px
// 19px

//-- Typography spacing
$typography-spacing: (
  1: 0.01875rem,
  // 0.3px
    2: 0.0375rem,
  // 0.6px,
    3: 0.04375rem,
  // 0.7px,
    4: 0.05rem,
  // 0.8px,
    5: 0.06875rem,
  // 1.1px,
);

//-- Typography accent
$typography-accent: (
  primary: #4badcf,
  gray-0: map-get($colors, gray-300-color),
  gray-1: map-get($colors, gray-400-color),
  gray-2: map-get($colors, gray-500-color),
  gray-3: map-get($colors, gray-600-color),
  gray-4: map-get($colors, gray-700-color),
  gray-5: map-get($colors, gray-800-color),
  gray-6: map-get($colors, gray-900-color)
);

//-- Gradients
$gradients: (
  1: linear-gradient(to left, #71eaff, #b5d5ff 49%, #f0d8ff),
  2: linear-gradient(to left, #f2ff71, #71fff0 49%, #7ddeff),
  3: linear-gradient(to top, #b3f9e4, #e2f5bd),
  4: linear-gradient(215deg, #14b3df, #16bceb),
  5: linear-gradient(143deg, rgba(75, 144, 205, 0.03), rgba(23, 70, 143, 0.43))
);

//-- Media sizes
$desktop-container: 73.125rem; // 1170px
$gutter-width: 0.9375rem;

//-- Media Breakpoints
$breakpoints: (
  xs: 576px,
  sm: 768px,
  md: 960px,
  lg: 1140px
);

$breakpoint-xs: 576px;
$breakpoint-sm: 768px;
$breakpoint-md: 1025px;
$breakpoint-lg: 1280px;

//-- Header
$header-height--xs: 4rem; // 5rem;
$header-height--lg: 4.3rem; // 5.875rem;
// $header-height--lg: 120px;

//-- Footer
$footer-height--lg: 28.75rem;
