@import "../../../assets/stylesheets/config/index";

.a-hr {
    display: block;
    margin: auto;
    border: none;
    border-top: 1px solid #979797;
    opacity: 0.10;

    &--lg-size {
        // margin-top: $size-unit-6;
        // margin-block-end: $size-unit-6;
    }
}
