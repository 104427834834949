@import "../../../assets/stylesheets/config/index";

.o-footer {
    background-color: map-get($colors, navy-900-color);
    color: #e0f7ff;

    @include respond-below(md) {
        padding: 2.4375rem 0.9375rem 2.25rem;
    }

    @include respond-above(md) {
        // height: $footer-height--lg;
        padding: 5.625rem 1.875rem;
    }

    a {
        transition: color 264ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    .a-button {
        width: 5.1875rem;
        background-color: #5294B0;
        font-size: 0.875rem;
    }

    .a-hr {
        order: 4;
        width: 100%;
        margin: 2.125rem 0;
        border-top-color: #4badcf;

        @include respond-above(md) {
            margin: 4rem 0 2.5rem;
        }
    }

    .a-input {
        background-color: #044E68;
        font-size: 0.875rem;
        color: #287D9B;

        &::-webkit-input-placeholder {
            color: #287D9B;
        }

        &::-moz-placeholder {
            color: #287D9B;
        }

        &:-ms-input-placeholder {
            color: #287D9B;
        }

        &:-moz-placeholder {
            color: #287D9B;
        }
    }

    .a-overline {
        margin-bottom: 0.75rem;
        font-family: map-get($typography-font, overline);
        color: #3E94B5;
    }

    .m-form-row {
        margin-top: 0.875rem;

        @include respond-above(md) {
            margin-top: 1.25rem;
        }
    }

    &__container {
        display: flex;
        flex-flow: row wrap;

        @include respond-below(md) {
            justify-content: center;
            text-align: center;
        }

        @include respond-above(md) {
            justify-content: space-between;
        }
    }

    &__menu {
        li + li {
            margin-top: 0.5rem;
        }

        a {
            position: relative;
            line-height: 1.6;
            color: #e0f7ff;

            @include respond-above(md) {
                &:after {
                    content: "";
                    width: 0;
                    height: 1px;
                    position: absolute;
                    top: 100%;
                    left: 0;
                    background-color: #e0f7ff;
                    transition: width 264ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                }
    
                &:hover:after {
                    width: 100%;
                }
            }
        }
    }

    &__logo {
        width: 3.5625rem;
        height: 40px;
        overflow: hidden;

        @include respond-below(md) {
            order: 0;
            overflow: hidden;
            margin-bottom: 2.125rem;
        }

        @include respond-above(md) {
            order: 7;
        }

        img {
            display: block;
            max-width: inherit;
            width: 19.875rem;
        }
    }

    &__copy {
        @include respond-below(md) {
            order: 1;
            width: 100%;
            margin-bottom: 3.375rem;
        }

        @include respond-above(md) {
            order: 0;
            width: 32.632%;
        }

        @include respond-above(lg) {
            margin-right: 8%;
        }

        p {
            @include body-font();
            line-height: 1.76;
        }
    }

    &__first-menu {
        @include respond-below(md) {
            order: 2;
            width: 50%;
            padding: 0.5rem 2rem 0.5rem 0;
            border-right: 1px solid rgba(29, 77, 102, 0.4);
        }

        @include respond-above(md) {
            order: 1;
        }

        @include respond-above(lg) {
            margin-right: 6.9%;
        }
    }

    &__second-menu {
        @include respond-below(md) {
            order: 3;
            width: 50%;
            padding: 0.5rem 0 0.5rem 2rem;
        }

        @include respond-above(md) {
            order: 2;
        }

        @include respond-above(lg) {
            flex-grow: 1;
        }
    }

    &__newsletter {
        @include respond-below(md) {
            order: 4;
            width: 100%;
            margin: 2.3125rem 0 0;
            padding: 2rem 0 0;
            border-top: 1px solid rgba(29, 77, 102, 0.4);
        }

        @include respond-above(md) {
            order: 3;
            width: 26%;
        }

        .a-overline {
            @include respond-below(md) {
                margin-bottom: 0.5rem;
            }
        }

        p {
            line-height: 1.6;
        }
    }

    &__copyright {
        order: 6;
        opacity: 0.5;
        align-self: center;
        font-size: map-get($typography-size, subtitle-3);
        color: #5694aa;
        
        @include respond-below(md) {
            flex-grow: 1;
            text-align: left;
        }

        @include respond-above(md) {
            order: 6;
        }
    }

    &__copyright-menu {
        order: 8;
        display: flex;
        align-self: center;

        li + li {
            @include respond-below(md) {
                margin-left: 1.875rem;
            }

            @include respond-above(md) {
                margin-left: 4rem;
            }
        }

        a {
            letter-spacing: 0.0375rem;
            text-transform: uppercase;
            font-size: map-get($typography-size, small);
            color: #427A91;

            &:hover {
                color: #fff;
            }
        }
    }

    &__advertiser-disclosure {
        order: 5;
        margin-bottom: 2.125rem;
        @include body-font();
        font-size: 12px;
        line-height: 1.76;

        @include respond-above(md) {
            order: 5;
            margin-bottom: 2.5rem;
        }
    }
}
