@import "../../../assets/stylesheets/config/index";

.o-paper {
    // height: 100%;
    padding: map-get($size, 3) - map-get($size, 1) / 2 map-get($size, 2);
    border-radius: map-get($border-radius, lg);
    line-height: 1.6;

    &.\--bg-light {
        background-color: #f7fbfc;
    }

    ul {
        font-size: 13px;
    }
}
