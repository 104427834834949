@import '../../../assets/stylesheets/config/index';

.o-social-modal.is-email-collection {
  background: #f8f9fb;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;

  h2 {
    margin-bottom: 42px;
  }

  h3 {
    margin-bottom: 16px;
  }

  .m-social-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include respond-above(sm) {
      flex-direction: row;
    }

    > span {
      display: block;

      @include respond-below(sm) {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    .a-button {
      width: 100%;

      @include respond-above(sm) {
        width: 210px;
        letter-spacing: -0.2px;
      }
    }

    .a-facebook-button {
      background-color: #4267b2;
      color: #fff;

      @include respond-above(sm) {
        margin-right: 8px;
      }
    }

    .a-google-button {
      border: none !important;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 0px 0px 0px !important;
      border-radius: 4px !important;

      > div {
        padding: 0 !important;
      }

      > span {
        padding: 0 !important;
      }
    }
  }

  .m-form-row {
    max-width: 428px;
  }

  .by-email {
    margin-bottom: 32px;
  }

  .o-modal__header {
    padding-bottom: 46px;
  }
}
