@import '../../../assets/stylesheets/config/index';

.a-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 6.875rem;
  height: 2.5rem;
  padding: 0.375rem 0.75rem;
  background: none;
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0 1px 3px 0 rgba(19, 65, 81, 0.13);
  transition: all 264ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  outline: none;
  cursor: pointer;
  letter-spacing: 0.05rem;
  font-weight: 500;

  @include respond-below(sm) {
    font-size: 0.875rem;
    letter-spacing: 0.025px;
  }

  &__icon {
    margin-right: 8px;
  }

  &--lg-size {
    height: 3rem;
    padding-right: 2rem;
    padding-left: 2rem;
    border-radius: 0.3125rem;
    font-size: 1rem;
  }

  &--xlg-size {
    height: 3.5rem;

    @include respond-between(sm, lg) {
      height: 40px;
    }
  }

  &--block {
    display: flex;
    width: 100%;
  }

  &--filter {
    justify-content: flex-start;
    border-radius: map-get($border-radius, lg);
    border: 1px solid #dcdfe4;
    box-shadow: none;
    color: #5d6065;

    .a-icon {
      margin-right: map-get($size, 2);
      margin-left: map-get($size, 1);
    }

    & + & {
      margin-top: map-get($size, 1);
    }
  }

  &--ghost-primary {
    color: map-get($colors, blue-400-color);

    &:hover {
      background-color: rgba(0, 0, 0, 0.06);
    }
  }

  &--invert-primary {
    background-color: #fff;
    color: #4badcf;
  }

  &--outline-primary {
    border: 2px solid #97d8e9;
    color: map-get($colors, blue-400-color);

    &:hover {
      background-color: map-get($colors, blue-400-color);
      border-color: map-get($colors, blue-400-color);
      color: #fff;
    }
  }

  &--outline-dark {
    border: 1px solid map-get($colors, gray-300-color);
    box-shadow: none;
    font-weight: 400;
    color: map-get($colors, gray-900-color);

    &:hover {
      background-color: map-get($colors, gray-300-color);
    }
  }

  &--primary {
    background-color: map-get($colors, blue-400-color);
    color: #fff;

    &:hover {
      background-color: darken(#13afd3, 4);
    }
  }

  &--link {
    padding: 0;
    background: none;
    border-radius: 0;
    border: none;
    line-height: 1;
    color: #4badcf;
    cursor: pointer;
    transition: all 264ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    outline: none;

    &:hover {
      color: darken(#4badcf, 20%);
    }
  }
}

.a-link {
  display: inline-block;
  position: relative;
  line-height: 1.6;
  font-weight: 500;
  color: map-get($typography-accent, gray-3);
  transition: color 264ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    color: inherit;
  }
}
