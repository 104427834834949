@import "../../../assets/stylesheets/config/index";

.a-bubble {
    display: flex;
    align-items: center;
    justify-content: center;
    width: map-get($size, 3);
    height: map-get($size, 3);
    background-color: map-get($colors, gray-100-color);
    border-radius: map-get($border-radius, lg);
    font-size: 0.8125rem;
    color: map-get($typography-accent, gray-2);
}
