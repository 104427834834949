@import "../../../assets/stylesheets/config/index";

.o-panel {
    border-radius: 0.3125rem;
    box-shadow: 0 3px 5px 0 rgba(29, 32, 37, 0.17);
    background-color: #ffffff;

    > p {
        margin-bottom: 2rem;
        color: map-get($typography-accent, gray-3);
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.75rem 2rem;

        .o-panel__title {
            font-size: 1.25rem;
            font-weight: 600;
            line-height: 1;
        }

        .arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2rem;
            height: 2rem;
            margin-right: - map-get($size, 1);
            padding: 0;
            box-shadow: none;
            transform: rotate(180deg);
            cursor: pointer;

            .is-collapsed & {
                transform: rotate(0deg);
            }
        }
    }

    &__body {
        padding: 0 2rem;
        transition: all 264ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

        &:first-child {
            padding-top: 1.75rem;
        }

        &:last-child {
            padding-bottom: 2rem;
        }

        .is-collapsed & {
            opacity: 1 !important;
        }

        .o-panel__title {
            margin-bottom: map-get($size, 3);
            font-size: 22px;
            font-weight: 600;
            line-height: 1;
        }
    }

    &__header + &__body {
        padding-top: 0;
    }

    &__collapse &__body {
        opacity: 0;
        padding-top: 0;
    }

    &__collapse {
        height: 0;
        overflow: hidden;
        transition: all 264ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    &.\--primary {
        box-shadow: 0 3px 5px 0 rgba(21, 85, 108, 0.13);
        background-image: linear-gradient(215deg, #13ADD8, #2DC3F4);

        * {
            color: #fff;
        }
    }

    &.\--only-body &__body {
        padding-top: 38px;
        padding-bottom: 38px;
        border-bottom: 1px solid rgba(234, 237, 242, 0.8);

        &:last-child {
            border: none;
        }
    }
}

.o-panel + .o-panel {
    margin-top: 1.25rem;

    @include respond-above(lg) {
        margin-top: 2.25rem;
    }
}

.with-gradient {
    position: relative;
    margin-top: 8px;

    &:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: -8px;
        left: 0;
        width: 100%;
        height: 15px;
        border-radius: 5px;
        box-shadow: inset 0 -1px 1px 0 rgba(0, 0, 0, 0.06);
    }

    &--1:before {
        background-image: linear-gradient(to left, #f2ff71, #71fff0 49%, #7ddeff);
    }

    &--2:before {
        background-image: linear-gradient(to left, #71eaff, #b5d5ff 49%, #f0d8ff);
    }
}