@import "../../../assets/stylesheets/config/index";

.o-article {
    display: flex;
    background-color: #fff;

    @include respond-below(sm) {
        padding: map-get($size, 4);
    }

    @include respond-above(sm) {
        padding: map-get($size, 3) map-get($size, 4);
        box-shadow: 0 3px 5px 0 rgba(29, 32, 37, 0.17), 0 1px 1px 0 rgba(29, 32, 37, 0.1), 0 0 1px 0 rgba(29, 32, 37, 0.11);
        border-radius: map-get($border-radius, lg);
    }

    @include respond-below(sm) {
        flex-direction: column;
    }

    &__image {
        @include respond-below(sm) {
            margin-bottom: map-get($size, 4);
            padding-bottom: 63.2%;
        }

        @include respond-above(sm) {
            flex-shrink: 0;
            width: 15.875rem;
            height: 12.75rem;
        }
    }

    &__content {
        @include respond-above(sm) {
            flex-grow: 1;
            padding: 0 0 0 map-get($size, 4);
        }
    }

    &__title {
        margin-bottom: map-get($size, 1) / 2;
        line-height: 1.3;
    }

    .a-meta {
        margin-bottom: map-get($size, 1);
    }

    .a-body2 {
        line-height: 1.6;
        color: map-get($typography-accent, gray-3);

        @include respond-below(sm) {
            margin-bottom: map-get($size, 3) - $size-unit / 2;
        }

        @include respond-above(sm) {
            margin-bottom: map-get($size, 1);
        }
    }

    .a-link {
        color: map-get($typography-accent, primary);

        @include respond-below(sm) {
            display: block;
            text-align: center;
        }
    }

    .m-rating {
        margin-bottom: map-get($size, 1);
    }

    & + & {
        @include respond-below(sm) {
            border-top: 1px solid map-get($colors, gray-color);
        }

        @include respond-above(sm) {
            margin-top: map-get($size, 4);
        }
    }
}
