@import "../../../assets/stylesheets/config/index";

.a-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: map-get($size, 3);
    height: map-get($size, 3);
}
