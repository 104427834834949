@import "../../../assets/stylesheets/config/index";

.o-crunch-values-piechart {
    height: 100%;
    padding: map-get($size, 3) map-get($size, 4) map-get($size, 5);
    background-color: #F7FBFC;
    border-radius: 5px;

    svg {
        max-width: 198px;
        margin: 0 auto map-get($size, 3);
    }
}
