.m-loader {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    background-color: #fff;

    .a-lazy-image {
        width: 202px;
        height: auto;
    }

    &--fixed {
        opacity: 0.8;
        visibility: visible;
    }

    &--dynamic {
        position: fixed;
        transition: opacity 264ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

        &.is-active {
            opacity: 0.8;
            visibility: visible;
        }
    }
}
