@import "../../../assets/stylesheets/config/index";

.m-checkbox-list {
    &--horizontal {
        display: flex;
        flex-flow: row wrap;

        .a-checkbox + .a-checkbox {
            margin-left: map-get($size, 1);
        }
    }

    &--vertical {
        .a-checkbox + .a-checkbox {
            margin-top: map-get($size, 1);
        }
    }

}
