@mixin overline-font {
    letter-spacing: map-get($typography-spacing, 3);
    line-height: 1.23;
    font-size: map-get($typography-size, overline);
    font-weight: 500;
    color: map-get($colors, gray-500-color);
}

@mixin body-font {
    font-family: map-get($typography-font, body);
}
