body.is-plaid-open {
    overflow-y: hidden !important;
}

body.is-plaid-closed {
    overflow-y: auto !important;
}

.o-manual-crunch-modal {
    max-width: 360px;
    padding: 2rem;

    &.o-modal__content {
        max-height: calc(100vh - 60px); // 60px represents Modal__overlay inner padding
    }
}