@import "../../../assets/stylesheets/config/index";

.m-slider {
    display: flex;
    flex-flow: row wrap;
    padding-bottom: 0.875rem;

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.625rem;
    }

    &__name {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-size: map-get($typography-size, overline);
        font-weight: 500;
        letter-spacing: 0.04375rem;
    }

    &__value {
        align-self: center;
        margin-right: 0;
        margin-left: auto;
    }

    .rangeslider {
        position: relative;
        width: 100%;
        height: 0.375rem;
        margin-top: map-get($size, 2);
        background-color: #ECF0F3;
        box-shadow: none;
        touch-action: none;
        
        &__fill {
            position: relative;
            height: 100%;
            overflow: hidden;
            background-color: map-get($colors, gray-400-color);
            border-radius: 10px;
            box-shadow: none;

            &:before {
                // content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                min-width: 320px;
                height: 100%;
                background-image: linear-gradient(to left, #f2ff71, #71fff0 49%, #4badcf)
            }

            &:after {
                // content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                min-width: 420px;
                height: 100%;
                // background-color: rgba(0, 0, 0, 0.1);
            }
        }

        &__handle {
            position: absolute;
            top: 50%;
            transform: translate3d(-50%, -50%, 0);
            outline: none;
            cursor: pointer;

            &-label {
                position: relative;
                z-index: 10;
                width: 1.5rem;
                height: 1.5rem;
                background-color: #fff;
                background-position: center;
                background-repeat: no-repeat;
                box-shadow: 0 2px 4px 0 rgba(34, 45, 61, 0.22), 0 1px 1px 0 rgba(196, 213, 219, 0.6);
                border-radius: 50%;
                border: none;
            }

            &:active:before {
                opacity: 0.3;
                width: 2.25rem;
                height: 2.25rem;
            }

            &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                border-radius: 50%;
                opacity: 0;
                background-color: rgba(75, 173, 207, 0.16);
                transition: all 264ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            }
        }

        &__labels {
            margin: 0;
        }

        &-horizontal,
        &--horizontal {
            border-radius: 2px;
        }
    }

    & + & {
        margin-top: map-get($size, 2) + $size-unit / 2;
    }
}