* {
  box-sizing: border-box;
}

html {
  font-size: 100%;
}

body {
  overflow-x: hidden;
  font-family: map-get($typography-font, interface), sans-serif, serif;
  font-size: map-get($typography-size, body-2);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.47;
  color: $body-color;

  &.is-menu-open {
    overflow: hidden;
    height: 100%;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  line-height: 1.18;
  color: $headings-color;
}

// h2,
// h3,
// h4,
// h5,
// h6 {
//     font-family: map-get($typography-font, heading), sans-serif, serif;
// }

h1 {
  @include respond-between(sm, md) {
    line-height: 1.41;
  }
}

h1,
h2,
h3 {
  font-weight: 600;
}

h4 {
  text-transform: uppercase;
  font-weight: 500;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
  color: $body-color;
}

b {
  font-weight: 600;
}

figure {
  margin: 0;
  padding: 0;
}

img {
  display: block;
  max-width: 100%;
}

strong {
  font-weight: 700;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;

  li p,
  .a-caption {
    line-height: 1.6;
  }
}

time {
  // text-transform: uppercase;
  font-size: map-get($typography-size, overline);
  letter-spacing: 0.028125rem;
  color: #a1a9b0;
  // color: #969ea7;
}

small {
  font-size: map-get($typography-size, small);
}

table {
  width: 100%;
  border-collapse: collapse;
}

.\--bg-gray {
  // background-color: map-get($colors, gray-color);
  background-color: #eeeff2;
}
