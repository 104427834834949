@import "../../../assets/stylesheets/config/index";

.o-plaid-panel {
    position: relative;
    box-shadow: 0 3px 5px 0 rgba(21, 85, 108, 0.13);

    h3 {
        position: relative;
        z-index: 10;
        margin-bottom: map-get($size, 1);
    }

    h3 + p {
        position: relative;
        z-index: 10;
        margin-bottom: map-get($size, 2);
        opacity: 0.8;
    }

    .a-button {
        position: relative;
        z-index: 10;
        margin-bottom: map-get($size, 3);
        background-color: #097594;
    }

    .a-caption {
        display: flex;
        opacity: 0.6;

        img {
            display: block;
            margin-right: map-get($size, 1);
        }
    }

    &__watermark {
        position: absolute;
        top: 0;
        right: 0;
        overflow: hidden;
        width: 210px;
        height: 170px;

        svg {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 310px;
            max-width: initial;
        }
    }
}
