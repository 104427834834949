@import '../../../assets/stylesheets/config/index';

.o-hero {
  display: flex;
  align-items: center;
  background-color: #014186;
  background-image: linear-gradient(143deg, rgba(75, 144, 205, 0.03), rgba(23, 70, 143, 0.43));
  overflow: hidden;

  @include respond-below(sm) {
    padding: (map-get($size, 6) - 0.375rem) 2rem;
  }

  .a-tag {
    margin-bottom: map-get($size, 3) - 0.125rem;
    color: #fff;

    @include respond-above(sm) {
      margin-bottom: map-get($size, 2);
    }
  }

  .a-h2 {
    margin-bottom: map-get($size, 4);
    color: #fff;
  }

  .a-meta {
    margin-top: map-get($size, 6);

    @include respond-above(sm) {
      margin-top: map-get($size, 5);
    }
  }

  .a-meta__item + .a-meta__item:before {
    background-color: rgba(255, 255, 255, 0.6);
  }

  .a-link {
    text-decoration: underline;
    font-size: 18px;
    color: #fff;
  }

  .t-container {
    position: relative;
  }

  &__content {
    position: relative;
    z-index: 10;
    color: #fff;

    h1 {
      margin-bottom: 1.375rem;
    }

    .a-h1 {
      margin-bottom: map-get($size, 5);
      color: #fff;
    }

    .a-body2 {
      opacity: 0.8;
    }

    .a-button {
      width: 7.375rem;

      img {
        width: 28px;
      }
    }

    .m-form-row {
      max-width: 36.5rem;
      margin-bottom: map-get($size, 1);
    }
  }

  &__watermark {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: initial;

    @include respond-below(sm) {
      width: 720px;
    }

    @include respond-above(sm) {
      transform: translate(-64%, -50%);
    }
  }

  &.\--xs {
    height: 22rem;
  }

  &.\--md {
    height: 19.5625rem;

    @include respond-above(lg) {
      height: 20.5rem; // 24.5rem;
      //   padding-top: 6.1rem;
      //   padding-bottom: 6.1rem;
    }
  }

  &.\--lg {
    height: 28.5rem;

    @include respond-above(sm) {
      height: 30rem;
    }
  }

  &.\--bg {
    background-color: #014186;
    background-image: map-get($gradients, 5);
    color: #fff;
  }
}

.o-featured-hero {
  @include respond-below(sm) {
    padding-right: 0;
  }

  .o-featured {
    &__title {
      opacity: 1;
      color: #fff;
    }
  }
}
