@import "../../../assets/stylesheets/config/index";

.o-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid rgba(151, 151, 151, 0.15);

    &.is-open {
        @include respond-below(md) {
            background-color: map-get($colors, navy-900-color);
        }
    }

    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        max-width: 90rem;
        margin: auto;

        @include respond-below(md) {
            height: $header-height--xs;
            padding: 0 2rem;
        }

        @include respond-above(md) {
            height: $header-height--lg;
            padding: 0 2.8125rem;
        }
    }

    &__brand {
        @include respond-below(md) {
            display: flex;
            align-items: center;
        }

        @include respond-above(md) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__burger {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.875rem;
        height: 1.875rem;
        margin: 0 0.875rem 0 -0.3125rem;
        cursor: pointer;

        @include respond-below(md) {
            order: 0;
        }

        @include respond-above(md) {
            display: none;
        }

        span {
            position: relative;
            display: block;
            width: 1.25rem;
            height: 2px;
            background-color: #282a2e;
            border-radius: 0.5rem;

            &:before,
            &:after {
                content: "";
                position: absolute;
                left: 0;
                display: block;
                width: 1.25rem;
                height: 2px;
                background-color: #282a2e;
                border-radius: 0.5rem;
            }

            &:before {
                top: -5px;
            }

            &:after {
                top: 5px;
            }

            .is-open & {
                transform: rotate(45deg);
                background-color: #fff;

                &:before,
                &:after {
                    background-color: #fff;
                }

                &:before {
                    opacity: 0;
                    top: 0;
                }

                &:after {
                    transform: rotate(-90deg);
                    top: 0;
                }
            }
        }
    }

    &__logo {
        display: block;
        overflow: hidden;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        @include respond-below(md) {
            order: 1;
            width: 2.9375rem;
            height: 33px;

            img {
                width: 16.4375rem;
                max-width: inherit;
            }
        }

        @include respond-above(md) {
            width: 12.625rem;
            height: 25px;

            img {
                width: 100%;
            }
        }
    }

    &__menu {
        display: none;
        margin: 0;
        padding: 0;

        @include respond-below(sm) {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            background-color: #fff;
            border-block-end: 1px solid rgba(151, 151, 151, 0.15);
        }

        @include respond-above(md) {
            display: flex;
            align-items: center;
        }

        a {
            text-transform: uppercase;
            letter-spacing: 0.05rem;
            font-weight: 500;
            font-size: map-get($typography-size, subtitle-3);
            cursor: pointer;
        }

        li + li {
            @include respond-between(md, lg) {
                margin-left: 1.65625rem;
            }

            @include respond-above(lg) {
                margin-left: 3.3125rem;
            }
        }
    }

    &__cta {
        text-transform: uppercase;
        font-size: 0.875rem;

        @include respond-below(md) {
            margin-right: -0.75rem;
            background: none;
            box-shadow: none;
            font-size: map-get($typography-size, overline);
            color: #13AFD3;
        }

        @include respond-above(md) {
            width: 187px;
            font-size: map-get($typography-size, 3);
        }
    }
}

.mobile {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
    width: 100%;
    height: 100%;
    flex-direction: column;
    height: 100vh;
    padding: 81px 1.875rem 2.25rem;
    background-color: map-get($colors, navy-900-color);

    @include respond-above(md) {
        display: none;
    }

    &__nav {
        text-align: center;
    
        a {
            display: block;
            cursor: pointer;
        }
    
        &.\--primary {
            margin-top: auto;
    
            a {
                font-size: 30px;
                font-weight: 500;
                line-height: 1.33;
                color: map-get($colors, gray-color);
            }
    
            a + a {
                margin-top: map-get($size, 2);
            }
        }
    
        &.\--secondary {
            margin-top: 82px;
    
            a {
                line-height: 2.13;
                color: #e0f7ff;
            }
        }
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: auto;
        padding-top: 2.125rem;
        border-top: 1px solid rgba(75, 173, 207, 0.1);

        p {
            opacity: 0.5;
            font-size: 14px;
            line-height: 1.93;
            color: #5694aa;
        }

        nav {
            display: flex;
            align-items: center;

            a {
                opacity: 0.6;
                font-size: 12px;
                line-height: 2.67;
                letter-spacing: 0.6px;
                text-transform: uppercase;
                color: #5694aa;
            }
        }
    }

    .is-menu-open & {
        display: flex;
    }
}
