@import "../../../assets/stylesheets/config/index";

.p-card {
    &__hero {
        align-items: initial !important;

        @include respond-below(sm) {
            height: auto !important;
            padding-top: 74px;
            padding-bottom: 74px;
        }

        @include respond-between(sm, md) {
            height: 320px;
        }

        img {
            border-radius: 15px;
        }

        .a-h2 {
            margin-bottom: 16px;

            @include respond-between(sm, md) {
                width: 320px;
            }
        }

        .a-button {
            width: 100%;
            margin-bottom: map-get($size, 1);

            @include respond-above(lg) {
                width: 262px;
            }

            &.\--link {
                text-decoration: underline;
                color: #fff;
            }
        }

        .a-button + .a-caption {
            text-align: center;
            color: #b9bcc1;

            @include respond-above(lg) {
                max-width: 262px;
            }
        }

        .a-meta {
            margin-top: 0;
        }

        .m-rating {
            margin-bottom: 24px;

            .a-caption:first-of-type {
                color: #f8f9fb;
            }
    
            .a-caption:last-of-type {
                white-space: nowrap;
                font-weight: 600;
            }
        }

        .t-container {
            display: flex;
            align-items: center;

            @include respond-below(sm) {
                flex-direction: column;
            }

            @include respond-between(sm, md) {
                justify-content: space-between;
            }
        }

        .o-hero__content {
            @include respond-below(sm) {
                order: 1;
                width: 100%;
                max-width:  267px;
                margin: auto;
                text-align: center;
            }

            @include respond-between(sm, md) {
                width: 224px;
                margin-left: 3.75rem;
            }

            @include respond-between(md, lg) {
                width: 262px;
                margin-right: 3.75rem;
            }

            @include respond-above(lg) {
                width: 426px; // 360px;
                margin-right: 3.75rem;
            }
        }

        .o-hero__object {
            @include respond-below(sm) {
                order: 0;
                width: 100%;
                max-width: 264px;
                height: 167px;
                margin: 0 auto map-get($size, 3);
            }

            @include respond-between(sm, md) {
                width: 223px;
                height: 141px;
                margin-right: 3.75rem;
            }

            @include respond-between(md, lg) {
                width: 260px;
                height: 164px;
            }

            @include respond-above(lg) {
                width: 264px;
                height: 167px;
            }
        }
    }

    &__content {
        background-color: #eeeff2;

        @include respond-between(sm, md) {
            padding: 20px 0 map-get($size, 8);
        }

        @include respond-above(md) {
            padding: 3rem 0 92px;
        }
    }

    &__aside {
        position: relative;
        z-index: 10;

        @include respond-above(md) {
            margin-top: -5.5rem;
        }

        .o-table-of-contents {
            display: none;

            @include respond-above(md) {
                display: block;
            }
        }
    }

    > .o-table-of-contents {
        @include respond-above(md) {
            display: none;
        }
    }

    .o-panel {
        position: relative;
    }   

    &__last-updated {
        position: absolute;
        bottom: 100%;
        left: 30px;
        padding-bottom: 6px;

        @include respond-between(sm, md) {
            padding-bottom: 2px;
        }
    }

    .\--sticky {
        display: none;
        position: fixed;
        z-index: 1010;
        top: 0;
        left: 0;
        width: 100%;
        padding-top: 12px;
        padding-bottom: 12px;
        background-color: #fff;
        border-bottom: 1px solid rgba(151, 151, 151, 0.15);
        border-top-right-radius: 0;
        border-top-left-radius: 0;

        @include respond-above(sm) {
            .o-offer {
                width: 705px;
            }
        }   

        @include respond-above(md) {
            .o-offer {
                width: 624px;
            }
        }

        @include respond-above(lg) {
            .o-offer {
                width: 752px;
            }
        }

        .o-offer {
            display: block;
            padding-top: 0;
            padding-bottom: 0;
            box-shadow: none;
        }

        .o-offer__image {
            width: 7.375rem;
            height: 4.3125rem;
        }

        .o-offer__body {
            width: 100%;

            @include respond-below(sm) {
                display: flex;
                align-items: center;
            }
        }

        .o-offer__caption {
            @include respond-below(sm) {
                margin-bottom: 0;
            }
        }

        .a-button {
            margin: 0;
        }

        .\--link {
            box-shadow: none;
            text-decoration: underline;
        }
    }
}

.o-table-of-contents {
    @include respond-below(md) {
        &, &:before {
            border-radius: 0;
        }
    }

    @include respond-between(sm, md) {
        .o-panel__header,
        .o-panel__body {
            width: 40.5rem;
            margin-right: auto;
            margin-left: auto;
        }
    }
}

.m-h {
    &__title {
        margin-bottom: 14px;
    }

    &__value {
        margin-bottom: 6px;
        font-size: 22px;
        line-height: 1;
    }
}

.m-credit {
    padding-top: 0;

    @include respond-below(md) {
        margin-top: 40px;
    }

    &__title {
        margin-bottom: 16px;
    }

    &__value {
        font-size: 19px;
        line-height: 1;
        color: #1d2025;
    }

    &__progress {
        height: 6px;
        margin-top: 8px;
        background-color: #E6ECF0;
        border-radius: 4px;

        &-trail {
            height: 100%;
            border-radius: 4px;
            box-shadow: 0 1px 4px 0 #dff1f7;
            background-image: linear-gradient(to left, #71eaff, #b5d5ff 49%, #f0d8ff);
        }
    }
}

.rte-content {
    p {
        margin-top: 15px;
    }
}

.o-h {
    display: flex;
    margin: 0 -24px 24px;

    .m-h {
        padding-right: 24px;
        padding-left: 24px;
    }
}

.o-fees {
    margin-bottom: 50px;

    @include respond-above(xs) {
        display: flex;
        flex-flow: row wrap;
        align-items: stretch;
        margin-right: -8px;
        margin-left: -8px;
    }

    .m-fees {
        @include respond-above(xs) {
            flex: 1 1;
            margin-right: 8px;
            margin-left: 8px;
        }
    }

    .m-fees + .m-fees {
        @include respond-below(xs) {
            margin-top: 16px;
        }
    }
}

.m-pros {
    & + & {
        margin-top: 16px;
    }
}

.m-card-benefit {
    & + & {
        margin-top: 24px;
    }
}

.m-additional-benefits {
    @include respond-below(md) {
        margin-top: 24px;
    }
}

.m-earn {
    margin-top: 24px;

    p + p {
        margin-top: 8px;
    }
}

.m-redeem {
    margin-top: 24px;
}

.m-optimize {
    margin-top: 24px;

    h3, p {
        font-weight: 400;
        font-size: inherit;
    }

    h3 + p,
    p + p {
        margin-top: 8px;
    }
}

.m-reward {
    display: flex;
    align-items: center;
    flex: 50%;
    border: 2px solid #fff;

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 28px;
        height: 24px;
        margin-right: 8px;
    }

    &__name {
        margin-right: 15px;
        white-space: nowrap;
        font-size: 13px;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 1;
        letter-spacing: 0.7px;
        color: #3e4146;
    }

    &__value {
        margin-right: 0;
        margin-left: auto;
        white-space: nowrap;
        font-size: 15px;
        font-weight: 400;
        color: #b9bcc1;
    }
}

.o-rewards {
    display: flex;
    flex-flow: row wrap;
    margin-right: -2px;
    margin-left: -2px;
}

.o-related-card {
    width: 264px;
    // height: 100%;
    // background-color: #f8f9fa;
    // box-shadow: 0 1px 2px 0 rgba(0,0,0,0.12);
    // border-radius: 0.25rem;

    &__image {
        height: 0;
        margin-bottom: 12px;
        padding-bottom: 63.2%;
    }

    &__name {
        margin-bottom: 6px;
        font-weight: 500;
        font-size: 15px;
    }
}



.slick-list,.slick-slider,.slick-track{position:relative;display:block}.slick-loading .slick-slide,.slick-loading .slick-track{visibility:hidden}.slick-slider{box-sizing:border-box;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-touch-callout:none;-khtml-user-select:none;-ms-touch-action:pan-y;touch-action:pan-y;-webkit-tap-highlight-color:transparent}.slick-list{overflow:hidden;margin:0;padding:0}.slick-list:focus{outline:0}.slick-list.dragging{cursor:pointer;cursor:hand}.slick-slider .slick-list,.slick-slider .slick-track{-webkit-transform:translate3d(0,0,0);-moz-transform:translate3d(0,0,0);-ms-transform:translate3d(0,0,0);-o-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}.slick-track{top:0;left:0}.slick-track:after,.slick-track:before{display:table;content:''}.slick-track:after{clear:both}.slick-slide{display:none;float:left;height:100%;min-height:1px}[dir=rtl] .slick-slide{float:right}.slick-slide img{display:block}.slick-slide.slick-loading img{display:none}.slick-slide.dragging img{pointer-events:none}.slick-initialized .slick-slide{display:block}.slick-vertical .slick-slide{display:block;height:auto;border:1px solid transparent}.slick-arrow.slick-hidden{display:none}/*# sourceMappingURL=slick.min.css.map */


.slick-track {
    // display: flex;
    margin-right: -8px;
    margin-left: -8px;

    > * {
        // height: inherit;
        padding: 0 8px;

        > * {
            // height: 100%;
        }
    }
}

.slick-arrow,
.slick-dots {
    display: none !important;
}

.\--sticky-header {
    display: none;
    position: fixed;
    z-index: 1002;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid rgba(151, 151, 151, 0.15);

    .t-container {
        height: 94px;
    }

    .\--offer {
        display: flex;
        align-items: center;

        &__image {
            width: 100px;
            height: 64px;
            margin-right: 20px;
    
            img {
                border-radius: 4px;
            }
        }

        &__body {
            display: flex;
            align-items: center;
        }

        &__caption {
            width: calc(100% - (9.25rem + 1.75rem));
        }

        &__cta {
            width: 10.25rem;
            margin-right: 0;
            margin-left: auto;
        }

        .a-caption {
            margin-top: 6px;
            text-align: center;
        }
    }
}

.\--fixed {
    .o-header {
        display: none;
    }

    .\--sticky {
        display: flex;
    }
}