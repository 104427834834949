@import "../../../assets/stylesheets/config/index";

.o-crunch-values-table {
    thead {
        @include respond-below(sm) {
            display: none;
        }
    }

    .a-icon {
        margin-right: 8px;
    }

    .is-total-spending {
        @include respond-below(sm) {
            display: none;
        }
    }

    .is-cashback {
        @include respond-below(sm) {
            display: none;
        }
    }

    .\--details {
        display: flex;
        align-items: center;
        margin-right: 10px;
    }

    .\--other {
        color: rgba(29, 77, 102, 0.48);
    }

    &.\--short-version {
        @include respond-below(sm) {
            margin-top: 32px;
        }

        @include respond-between(md, lg) {
            table-layout: fixed;
        }
    }
}