@import "../../../assets/stylesheets/config/index";

.m-highlight {
    display: inline-flex;
    flex-direction: column;
    flex: 0 0 50%;
    width: 50%;

    @include respond-above(sm) {
        flex: 0 0 25%;
        width: 25%;
    }

    h4 {
        margin-bottom: map-get($size, 1) / 2;
    }
}
