@import "../../../assets/stylesheets/config/index";

input[type="email"],
input[type="number"],
input[type="password"],
input[type="text"],
.a-input {
    display: block;
    width: 100%;
    height: 2.5rem;
    padding: 0.5rem 1rem;
    background-color: #ffffff;
    border: none;
    border-radius: 0.25rem;
    // border-radius: $border-radius-default;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08);
    font-weight: 400;
    font-size: 0.9375rem;
    color: #7d828f;

    &.\--xlg-size {
        height: 3.5rem;
        padding: map-get($size, 1) map-get($size, 3);

        @include respond-between(sm, lg) {
            height: 40px;
        }
    }

    &[disabled] {
        opacity: 0.4;
    }

    &::-webkit-input-placeholder {
        font-weight: 300;
        color: #C1C6CB;
    }

    &::-moz-placeholder {
        font-weight: 300;
        color: #C1C6CB;
    }

    &:-ms-input-placeholder {
        font-weight: 300;
        color: #C1C6CB;
    }

    &:-moz-placeholder {
        font-weight: 300;
        color: #C1C6CB;
    }
}
