@import "../../../assets/stylesheets/config/index";

.o-dropdown {
    border: 1px solid map-get($colors, gray-300-color);
    border-radius: map-get($border-radius, lg);
    //
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    //

    &__toggle {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: map-get($size, 2) - 0.25rem map-get($size, 3);
        color: map-get($typography-accent, gray-4);
        cursor: pointer;

        .arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2rem;
            height: 2rem;
            margin-right: - map-get($size, 1);
            padding: 0;
            box-shadow: none;
            transform: rotate(180deg);
            cursor: pointer;

            .is-collapsed & {
                transform: rotate(0deg);
            }
        }
    }

    &__menu {
        height: 0;
        overflow: hidden;
        transition: all 264ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

        > div {
            padding: 0 map-get($size, 3) map-get($size, 3);
            opacity: 0;
            transition: all 264ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

            .is-collapsed & {
                opacity: 1;
            }
        }
    }

    // & + & {
    //     margin-top: map-get($size, 5);
    // }
}