.o-advertiser {
    &__trigger {
        margin-bottom: 16px;
        padding: 0;
        background: none;
        border: none;
        box-shadow: none;
        outline: none;
        font-weight: 600;
        color: #13AFD3;
        cursor: pointer;
    }
}