@import '../../../assets/stylesheets/config/index';

.p-credit-cards {
  @include respond-between(sm, lg) {
    padding-bottom: 70px;
  }

  @include respond-above(lg) {
    padding-bottom: 6.5rem;
  }

  .o-hero {
    align-items: center;

    .a-h1 {
      @include respond-between(sm, lg) {
        margin-bottom: 24px;
      }
    }

    .a-button {
      @include respond-below(sm) {
        display: none;
      }
    }

    .m-form-row {
      @include respond-between(sm, lg) {
        margin-bottom: 24px;
      }
    }
  }

  .o-plaid-panel {
    @include respond-below(sm) {
      margin: 0 32px 32px;
    }
  }

  .o-panel.with-gradient {
    @include respond-below(sm) {
      border-radius: 0;

      &:before {
        border-radius: 0;
      }
    }
  }

  &__content {
    @include respond-above(sm) {
      padding-top: 3rem;
    }
  }

  &__aside {
    position: relative;
    z-index: 10;

    @include respond-above(md) {
      margin-top: -2.5rem;
    }
  }

  &__pagination {
    text-align: center;

    @include respond-below(sm) {
      padding: 56px 0;
    }

    @include respond-between(sm, md) {
      padding: 64px 0;
    }

    @include respond-between(md, lg) {
      padding-top: 64px;
    }

    @include respond-above(lg) {
      padding-top: 4.5rem;
    }

    .a-button {
      width: 10.3125rem;
    }
  }
}

.--remove-min-height {
  min-height: auto;
}

.p-top-picks__hero {
  align-items: center !important;

  .a-h1 {
    font-size: 2.125rem;

    @include respond-above(lg) {
      font-size: 2.5rem;
    }
  }

  .a-h1 span {
    display: inline-flex;
    width: 10px;
    height: 10px;
    margin-left: 4px;
    background-color: #fff;
    border-radius: 50%;
    /**
        * Use the blink animation, which is defined above
        */
    animation-name: blink;
    /**
        * The animation should take 1.4 seconds
        */
    animation-duration: 1.4s;
    /**
        * It will repeat itself forever
        */
    animation-iteration-count: infinite;
    /**
        * This makes sure that the starting style (opacity: .2)
        * of the animation is applied before the animation starts.
        * Otherwise we would see a short flash or would have
        * to set the default styling of the dots to the same
        * as the animation. Same applies for the ending styles.
        */
    animation-fill-mode: both;

    &:first-child {
      margin-left: 8px;
    }
  }

  .a-h1 span:nth-child(2) {
    /**
        * Starts the animation of the third dot
        * with a delay of .2s, otherwise all dots
        * would animate at the same time
        */
    animation-delay: 0.2s;
  }

  .a-h1 span:nth-child(3) {
    /**
        * Starts the animation of the third dot
        * with a delay of .4s, otherwise all dots
        * would animate at the same time
        */
    animation-delay: 0.4s;
  }
}

@keyframes blink {
  /**
    * At the start of the animation the dot
    * has an opacity of .2
    */
  0% {
    opacity: 0.1;
  }
  /**
    * At 20% the dot is fully visible and
    * then fades out slowly
    */
  20% {
    opacity: 1;
  }
  /**
    * Until it reaches an opacity of .2 and
    * the animation can start again
    */
  100% {
    opacity: 0.1;
  }
}
