@import "../../../assets/stylesheets/config/index";

.o-featured {
    position: relative;
    flex-shrink: 0;
    width: 9.375rem;

    a {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
    }

    &__image {
        position: relative;
        margin-bottom: map-get($size, 3);
        padding-bottom: 63.2%;
        box-shadow: 0px 34px 29px -21px rgba(0, 0, 0, 0.5);

        img {
            border-radius: map-get($border-radius, lg);
        }
    }

    &__title {
        opacity: 1;
        text-transform: initial;
        line-height: 1.27;
        font-weight: 400;
    }

    & + & {
        margin-left: map-get($size, 2);
    }
}

.o-featured-cards {
    display: flex;
    align-items: flex-start;
    max-width: 40.625rem;
}
