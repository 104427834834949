@import '../../../assets/stylesheets/config/index';

.o-offer {
  // display: none;
  display: flex;
  background-color: #fff;
  border-radius: map-get($border-radius, lg);

  @include respond-below(sm) {
    flex-flow: column wrap;
    padding: 32px;
    border-radius: 0;
  }

  @include respond-between(sm, md) {
    padding: 32px 24px;
  }

  @include respond-above(md) {
    padding: map-get($size, 4);
  }

  @include respond-above(sm) {
    flex-flow: row wrap;
    box-shadow: 0 3px 5px 0 rgba(29, 32, 37, 0.17), 0 1px 1px 0 rgba(29, 32, 37, 0.1),
      0 0 1px 0 rgba(29, 32, 37, 0.11);
  }

  .a-badge {
    margin-bottom: map-get($size, 1);
  }

  .a-subtitle2 {
    margin-bottom: map-get($size, 1);
    line-height: 1.26;
    cursor: pointer;
  }

  .a-meta {
    @include respond-below(sm) {
      margin-top: 24px;
    }
  }

  .m-highlight {
    @include respond-below(sm) {
      margin: map-get($size, 3) 0 0 auto;
    }
  }

  &__image {
    order: 0;
    overflow: hidden;
    border-radius: map-get($border-radius, lg);
    background-color: #f8f9fb;
    cursor: pointer;

    @include respond-below(sm) {
      margin-bottom: map-get($size, 4);
      padding-bottom: 63.2%;
    }

    @include respond-above(sm) {
      width: 8.375rem;
      height: 5.3125rem;
      margin-left: 0;
    }
  }

  &__body {
    order: 1;

    @include respond-above(sm) {
      display: flex;
      flex-flow: row wrap;
      //   align-items: center;
      width: calc(100% - (8.375rem + 1.75rem));
      margin-right: 0;
      margin-left: auto;
    }
  }

  &__caption {
    width: 100%;

    @include respond-below(sm) {
      margin-bottom: map-get($size, 3);
    }

    @include respond-above(sm) {
      max-width: calc(100% - (8.375rem + 1.75rem));
      // margin-bottom: 18px;
    }

    > b {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      > svg {
        margin-right: 10px;
      }
    }
  }

  &__body &__cta {
    text-align: center;

    @include respond-above(sm) {
      width: 8.375rem; //10.25rem;
      margin-right: 0;
      margin-left: auto;
    }

    .a-link {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 40px;
      font-size: 15px;
      line-height: 1.2;
      text-decoration: underline;
    }

    .a-button {
      margin-bottom: map-get($size, 1);

      @include respond-below(sm) {
        max-width: 100%; // 10.25rem;
      }
    }

    .a-button.--link {
      box-shadow: none;
      color: #13afd3;
    }

    .a-caption {
      display: flex;
      justify-content: center;
      white-space: nowrap;

      @include respond-below(sm) {
        width: 100%; // 164px;
      }
    }
  }

  &__highlights {
    order: 3;

    @include respond-above(sm) {
      //   width: 100%;
      width: calc(100% - (8.375rem + 1.75rem));
      margin: map-get($size, 2) 0 0 auto;
    }

    @media (min-width: 1025px) and (max-width: 1279px) {
      .m-highlight:not(.is-default):last-child {
        .a-overline {
          width: 50%;
        }
      }
    }

    &.is-default {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      @media (min-width: 768px) {
        width: 50%;
      }

      .m-highlight {
        flex: 0 0 50%;
        width: 50%;

        &:first-child {
          @media (min-width: 768px) {
            padding-right: 24px;
          }
        }

        &:last-child {
          @media (min-width: 768px) {
            padding-left: 24px;
          }
        }

        .a-overline {
          line-height: 1.85;
        }

        .a-body2 {
          line-height: 1.1;
          font-size: 20px;
        }

        small {
          margin-top: 6px;
          line-height: 1.23;
          font-size: 13px;
          color: #9a9da2;
        }
      }
    }
  }

  > .o-offer__cta {
    order: 2;
    margin-top: map-get($size, 2);
    text-align: center;
    width: 8.375rem;

    @include respond-below(sm) {
      &:not(.is-default) {
        order: 4;
        width: 100%; // 164px;
      }
    }

    a {
      display: block;
      font-size: 13px;
      color: #13afd3;
    }

    &.is-default {
      display: flex;
      flex-flow: row wrap;
      align-self: flex-start;
      width: 100%;
      text-align: left;

      @media (min-width: 768px) {
        width: 50%;
      }

      .a-button {
        width: 164px;
        height: 40px;
        color: #4badcf;

        @media (min-width: 768px) {
          width: 134px;
        }
      }

      .a-overline {
        width: 100%;
        margin-bottom: 0.25rem;
        line-height: 1.85;
      }

      small {
        display: flex;
        align-items: center;
        width: calc(100% - 164px);
        padding-left: 18px;
        line-height: 1.23;
        font-size: 13px;
        color: #9a9da2;

        @media (min-width: 768px) {
          width: 50%;
        }
      }
    }
  }

  & + & {
    @include respond-below(sm) {
      border-top: 1px solid rgba(234, 237, 242, 0.8);
    }

    @include respond-between(sm, lg) {
      margin-top: map-get($size, 2);
    }

    @include respond-above(lg) {
      margin-top: 2rem;
    }
  }

  &.has-crunch-values {
    position: relative;

    .a-button--link {
      text-align: center;

      @include respond-above(sm) {
        width: 134px;
        position: absolute;
        top: 138px;
        font-size: 13px;
      }

      @include respond-between(sm, md) {
        left: 24px;
      }

      @include respond-above(md) {
        left: 32px;
      }
    }
  }

  .tabs {
    order: 4;
    width: 100%;
    margin-top: 1.2rem;
    padding-top: 1.2rem;
    border-top: 1px solid rgba(234, 237, 242, 0.8);

    &__navigation {
      margin-left: -1rem;

      @media (max-width: 767px) {
        overflow: hidden;
        margin-right: -32px;
        margin-left: -32px;

        &__container {
          overflow-y: hidden;
          overflow-x: auto;
          margin-bottom: -17px;
          padding-bottom: 17px;
        }
      }

      &__container {
        display: flex;
      }

      a {
        position: relative;
        margin-top: -1.25rem;
        margin-bottom: -1.25rem;
        padding: 1.25rem 1rem;
        transition: all 264ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        flex-shrink: 0;

        @media (max-width: 767px) {
          &:first-child {
            margin-left: 16px;
          }
        }

        @media (min-width: 768px) {
          margin-left: 16px;

          &:first-child {
            margin-left: 0;
          }
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0.65rem;
          right: 1rem;
          left: 1rem;
          opacity: 0;
          height: 3px;
          background-color: #4badcf;
          border-radius: 4px;
          transition: all 264ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        }

        &:hover {
          color: #1d2025;
        }

        &.is-active {
          color: #4badcf;

          &:after {
            opacity: 1;
          }
        }
      }
    }

    &__panel {
      padding-top: 1.25rem;

      p,
      .a-body2,
      .m-list,
      .rte-content,
      .rte-content h3 {
        font-size: 13px;
      }

      .rte-content {
        margin-top: 15px;

        p:first-child {
          margin-top: 0;
        }
      }

      table {
        width: 100%;
        border-collapse: collapse;

        thead {
          th,
          td {
            padding: 6px 1rem;
            text-align: left;
          }
        }

        tbody {
          tr {
            th,
            td {
              padding: 6px 1rem;
              font-size: 13px;
            }

            &:nth-child(odd) {
              td {
                background-color: #f7fbfc;
              }
            }

            &:nth-child(even) {
              td {
                background-color: white;
              }
            }
          }
        }

        .is-your-spending,
        .is-cashback,
        .is-rewards {
          text-align: right;
        }
      }

      .m-reward {
        flex: 100% 1;
        padding: 6px 1rem;
        border: none;

        &:nth-child(even) {
          background-color: #fff;
        }

        &:nth-child(odd) {
          background-color: #f7fbfc;
        }
      }

      .more-details {
        .more-details__content {
          position: relative;
        }

        .more-details__link {
          margin-top: 8px;
          text-decoration: underline;
          font-size: 13px;
          cursor: pointer;
          color: #13afd3;
        }

        .b {
          height: 72px;
          overflow: hidden;

          @media (min-width: 1024px) {
            &:after {
              content: '';
              position: absolute;
              right: 0;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background: rgb(255, 255, 255);
              background: linear-gradient(
                0deg,
                rgba(255, 255, 255, 1) 0%,
                rgba(255, 212, 119, 0) 100%
              );
            }
          }
        }

        .a {
          height: auto;
        }
      }
    }

    @media (min-width: 768px) {
    }
  }

  .pros-and-cons {
    @media (max-width: 767px) {
      .o-paper + .o-paper {
        margin-top: 16px;
      }
    }

    @media (min-width: 768px) {
      display: flex;
      margin-right: -8px;
      margin-left: -8px;

      .o-paper {
        width: 50%;
        margin-right: 8px;
        margin-left: 8px;
      }
    }
  }
}

.o-offer:not(.\--ls) {
  .a-button {
    // background: none;
    // box-shadow: none;
    // color: #13AFD3;

    @include respond-below(sm) {
      font-size: 15px;
    }
  }
}

// .o-offer.is-visible {
//   display: flex;
// }

// .o-offer.is-not-visible {
//   display: none;
// }
