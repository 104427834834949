@import "../../../assets/stylesheets/config/index";

.a-tag {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 1.75rem;
    padding: 0 0.75rem;
    background-image: map-get($gradients, 3);
    border-radius: 0.875rem;
    letter-spacing: 0.01875rem;
    line-height: 1;
    text-transform: uppercase;
    font-weight: 600;
    font-size: map-get($typography-size, small);
    color: #519463;

    @include respond-above(sm) {
        height: 2.0625rem;
        border-radius: 1rem;
    }

    img {
        margin-right: map-get($size, 1);
    }

    &__cta {
        display: none;
        width: 10px;
        height: 10px;
        cursor: pointer;
    }

    &.\--no-bg {
        height: inherit;
        background: none;
        border-radius: 0;
        padding: 0;
        font-size: map-get($typography-size, caption);
    }
}
