.o-table {
    text-align: left;

    tr {
        &:nth-child(odd) td {
            background-color: #F7FBFC;
        }
    
        &:nth-child(even) td {
            background-color: #fff;
        }

        th {
            padding: 0 16px 16px;

            &:first-child {
                padding-left: 0;
            }
        }

        td {
            padding: 16px;
            color: #b9bcc1;

            &:first-child {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }

            &:last-child {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }
    }
}