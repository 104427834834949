@import "../../../assets/stylesheets/config/index";

.o-thumbnail {
    padding: map-get($size, 2);
    background-color: #fff;
    border-radius: map-get($border-radius, lg);

    @include respond-below(sm) {
        flex-shrink: 0;
        width: 15.125rem;
    }

    .a-overline {
        margin-bottom: map-get($size, 2);
    }

    &__image {
        position: relative;
        height: 0;
        margin-bottom: map-get($size, 3) - 0.125rem;
        padding-bottom: 63.2%;

        img {
            border-radius: map-get($border-radius, lg);
        }
    }

    & + & {
        @include respond-below(sm) {
            margin-left: map-get($size, 1);
        }

        @include respond-above(sm) {
            margin-top: map-get($size, 2);
        }
    }

    &.is-active {
        position: relative;
        z-index: 10;
        box-shadow: 0 3px 5px 0 rgba(29, 32, 37, 0.17), 0 1px 1px 0 rgba(29, 32, 37, 0.1), 0 0 1px 0 rgba(29, 32, 37, 0.11);

        .a-overline {
            color: map-get($typography-accent, primary);
        }
    }
}
