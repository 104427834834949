@import "../../../assets/stylesheets/config/index";

.m-control-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: map-get($size, 2);

    &__title {
        line-height: map-get($size, 3);
        color: map-get($typography-accent, gray-5);
    }
}
