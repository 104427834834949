@import "../../../assets/stylesheets/config/index";

.m-decimal-list {
    counter-reset: item;

    li {
        padding: 0;
        font-size: map-get($typography-size, body-2);
        line-height: 1.47;
        color: #7e969e;

        @include respond-above(sm) {
            line-height: 1.6;
        }

        @include respond-between(sm, md) {
            max-width: 29rem;
        }

        a {
            display: flex;
            align-items: flex-start;
            color: #515e6d;

            &:before {
                content: counters(item, "") "" !important;
                counter-increment: item;
                display: block;
                flex-shrink: 0;
                width: 28px;
                color: #7e969e;

                @include respond-between(sm, md) {
                    width: 34px;
                }

                @include respond-between(md, lg) {
                    width: 22px;
                }

                @include respond-above(lg) {
                    width: 1.5625rem;
                }
            }

            &:visited {
                color: #515e6d;
            }
        }
    }

    li + li {
        margin-top: 14px;

        @include respond-between(sm, md) {
            margin-top: 8px;
        }

        @include respond-above(md) {
            margin-top: map-get($size, 1);
        }
    }
}

.m-bullet-list {
    li {
        position: relative;
        padding-left: 16px;

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 10px;
            left: 0;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: #9a9da2;
        }
    }
}
