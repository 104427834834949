@import "../../../assets/stylesheets/config/index";

.o-manual-crunch {
    h3 {
        margin-bottom: 0.5625rem;
        font-size: map-get($typography-size, subtitle-1);
        line-height: 1.6;
    }

    p {
        color: map-get($colors, gray-600-color);
    }

    .form__header {
        margin-bottom: 2.125rem;
    }

    .form__footer {
        margin-top: 1rem;
        text-align: center;

        .a-button + div {
            margin-top: 1rem + $size-unit / 2;
        }
    }

    .scroll-wrapper {
        max-height: 540px;
        height: calc(100vh - 308px);
        margin: 0 -1rem;

        .scrollbar-container {
            padding: 0 1rem;
        }
    }
}
