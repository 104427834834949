@import "../../../assets/stylesheets/config/index";

.a-radiobox {
    display: flex;
    position: relative;
    margin: 0;
    color: #515e6d;
    cursor: pointer;

    input {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        opacity: 0;
    }

    &__control {
        display: block;
        width: 1.125rem;
        height: 1.125rem;
        margin: 1px 1.25rem 0 0;
        border: 1px solid blue;
    }

    &__label {}

    input:checked + &__control {
        background-color: blue;
    }
}
