@import "../../../assets/stylesheets/config/index";

.m-form-row {
    display: flex;
    flex-direction: row;
    align-items: center;

    .a-input {
        flex-grow: 1;
    }

    .a-button {
        flex-shrink: 0;
    }

    .a-input + .a-button {
        margin-left: 0.5rem;
    }
}
