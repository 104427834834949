@import "../../../assets/stylesheets/config/index";

.a-checkbox {
    display: flex;
    position: relative;
    margin: 0;
    color: map-get($colors, gray-700-color);
    cursor: pointer;

    input {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        opacity: 0;
    }

    &__control {
        display: block;
        flex-shrink: 0;
        width: 2rem;
        height: 2rem;
        margin-right: map-get($size, 2);
        border: 1px solid map-get($colors, gray-300-color);
        border-radius: map-get($border-radius, lg);
    }

    &__label {
        display: block;
        padding-top: 6px;
    }

    input:checked + &__control {
        background-color: blue;
    }
}
