@import "../../../assets/stylesheets/config/index";

.a-responsive-embed {
    position: relative;
    width: 100%;
    height: 0;

    > *,
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &.\--16by9 {
        padding-bottom: 56.25%;
    }
}